/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any SCSS you import will output into a single css file
import '../styles/scss/styles.scss';

import TypeIt from 'typeit';
import Swiper, {Autoplay, Navigation, Pagination, Controller, Thumbs, EffectFade} from 'swiper';
import 'swiper/css';
import 'swiper/css/controller';
import 'swiper/css/thumbs';
import 'swiper/css/effect-fade';
import validate from 'validate.js';
import Choices from 'choices.js';
import './components/solutions/dom-animations';
import $ from 'jquery';

import {load} from 'recaptcha-v3'
import * as web from '@sulu/web';
import AjaxLink from './components/AjaxLink';

web.registerComponent('ajax-link', AjaxLink);

window.web = web;

Swiper.use([Autoplay, Navigation, Pagination, Controller, Thumbs, EffectFade]);

// start the Stimulus application
window.addEventListener('load', function (qualifiedName, value) {

    // general
    window.ext = {
        get getCurrentScreenWidth() {
            return window.innerWidth
        },
        get isHome() {
            return document.body.classList.contains('page-home')
        }
    };

    const __CARD_HOLDER = 'Curtis Clement';
    const __CARD_NUMBER = '4242 4265 4564 7800';
    const __CARD_DATE = '12/23';
    const __CARD_CVC = '040';
    const __CODE = '{<br>' +
        '&nbsp;&nbsp;"client":&nbsp;{<br>' +
        '&nbsp;&nbsp;&nbsp;&nbsp;<span class="code__color--1">"email"</span>:&nbsp;"client@democompany.com"<br>' +
        '&nbsp;&nbsp;},<br>' +
        '&nbsp;&nbsp;&nbsp;&nbsp;"products":&nbsp;service&nbsp;delivery<br>' +
        '&nbsp;&nbsp;&nbsp;&nbsp;{<br>' +
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"price":&nbsp;<span class="code__color--2">674.00,</span><br>' +
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"title":&nbsp;<span class="code__color--3">"Demo&nbsp;Company&nbsp;Service"</span><br>' +
        '&nbsp;&nbsp;&nbsp;&nbsp;}<br>' +
        '&nbsp;&nbsp;}<br>' +
        '}';

    // Tabs
    try {
        const tabs_controls = document.querySelector('.tabsSchema .tabs__controls');

        tabs_controls.addEventListener('click', e => {
            let buttons = tabs_controls.querySelectorAll('li');
            let tabs = tabs_controls.parentNode.querySelectorAll('.tabs__tab');
            let tabsSecond = document.querySelectorAll('.tabs__info');
            let index = [...buttons].indexOf(e.target.parentNode);

            if (index >= 0) {
                buttons.forEach((btn, i) => i === index ? btn.classList.add('active') : btn.classList.remove('active'));
                tabs.forEach((tab, i) => i === index ? tab.classList.add('active') : tab.classList.remove('active'));
                tabsSecond.forEach((tab, i) => i === index ? tab.classList.add('active') : tab.classList.remove('active'));
            }
        });

    } catch (err) {
        // console.warn(err);
    }

    // Tabs NEW
    try {
        const section = document.querySelector('.scheme'),
            tabs_controls = section.querySelector('.tabs__controls');

        tabs_controls.addEventListener('click', e => {
            let buttons = tabs_controls.querySelectorAll('.scheme__control');
            let tabs = tabs_controls.parentNode.querySelectorAll('.tabs__tab');
            let tabsSecond = section.querySelectorAll('.scheme__info .tabs__tab');
            let index = [...buttons].indexOf(e.target.parentNode);

            if (index >= 0) {
                buttons.forEach((btn, i) => i === index ? btn.classList.add('active') : btn.classList.remove('active'));
                tabs.forEach((tab, i) => i === index ? tab.classList.add('active') : tab.classList.remove('active'));
                tabsSecond.forEach((tab, i) => i === index ? tab.classList.add('active') : tab.classList.remove('active'));
            }
        })

    } catch (err) {
        // console.warn(err);
    }

    // Tabs Products
    try {
        let tabControls = document.querySelectorAll('.products_page .tabs__controls'),
            tabButtons = document.querySelectorAll('.products_page .tabs__controls .tabs__btn'),
            tabPanels = document.querySelectorAll('.products_page .tabs__content .tabs__tab'),
            hash = location.hash

        tabControls.forEach(control => {
            control.addEventListener('click', function (event) {
                let target = event.target

                if (target.classList.contains('tabs__btn')) {
                    let attr = target.getAttribute('data-toggle')

                    tabsAction(attr)
                }
            })
        })

        if (hash) {
            tabsAction(getHash(hash))
        } else {
            tabsAction()
        }

        function getHash(url) {
            let hashPos = url.lastIndexOf('#')

            return url.substring(hashPos + 1)
        }

        function tabsAction(target) {
            for (let i = 0; i < tabButtons.length; i++) {
                let attr = tabButtons[i].getAttribute('data-toggle')

                if (target) {
                    if (attr === target) {
                        tabButtons[i].classList.add('active')
                    } else {
                        tabButtons[i].classList.remove('active')
                    }
                } else {
                    if (i % 2 === 0) {
                        tabButtons[i].classList.add('active')
                    }
                }
            }

            for (let i = 0; i < tabPanels.length; i++) {
                let attr = tabPanels[i].getAttribute('data-content')

                if (target) {
                    if (attr === target) {
                        tabPanels[i].classList.add('active')
                    } else {
                        tabPanels[i].classList.remove('active')
                    }
                } else {
                    if (i % 2 === 0) {
                        tabPanels[i].classList.add('active')
                    }
                }
            }
        }
    } catch (err) {
        // console.warn(err);
    }

    // Accordion
    try {
        function accordionInit(accordion) {
            const items = accordion.querySelectorAll('.accordion__item')
            const openedItem = item => {
                const opened_item = accordion.querySelector('.accordion__item--active')
                toggle_item(item)
                if (opened_item && opened_item !== item) {
                    toggle_item(opened_item)
                }
            }

            items.forEach(item => {
                const title = item.querySelector('.accordion__button')
                title.addEventListener('click', () => {
                    openedItem(item)
                })

                title.addEventListener('keydown', event => {
                    if (event.code === 'Enter' || event.code === 'Space') {
                        event.preventDefault()
                        openedItem(item)
                    }
                })
            })

            const toggle_item = item => {
                const body = item.querySelector('.accordion__collapse')
                const text = item.querySelector('.accordion__body')

                if (item.classList.contains('accordion__item--active')) {
                    item.classList.remove('accordion__item--active')
                    body.style.height = '0px'

                    window.addEventListener('resize', () => {
                        body.style.height = '0px'
                    })
                } else {
                    body.style.height = body.scrollHeight + 'px'
                    item.classList.add('accordion__item--active')

                    window.addEventListener('resize', () => {
                        if (text.style.height > body.style.height) {
                            body.style.height = body.scrollHeight + 'px'
                        } else {
                            body.style.height = text.scrollHeight + 'px'

                        }
                    })
                }
            }
        }

        if (document.querySelector('.accordion')) {
            const accordions = document.querySelectorAll('.accordion')
            accordions.forEach(accordion => {
                accordionInit(accordion)
            })
        }
    } catch (err) {
        // console.warn(err);
    }

    // Header
    try {
        const header = document.querySelector('.header');
        const docs_nav = document.querySelector('.docs_page .sidebar');
        const header_burger = document.querySelector('.header .burger');
        const docs_close = document.querySelector('.docs_page .close');
        const header_close = document.querySelector('.header .close');
        const header_menu = document.querySelector('.header .menu');
        // const header_child = document.querySelectorAll('.header .menuChild');

        header_burger.addEventListener('click', () => {
            if (header.classList.contains('header_docs')) {
                docs_nav.classList.add('active');
            } else {
                header_menu.classList.add('active');
            }
            document.body.classList.add('no-scroll');
        });

        if (header.classList.contains('header_docs')) {
            docs_close.addEventListener('click', () => {
                docs_nav.classList.remove('active');
                document.body.classList.remove('no-scroll');
            });
        } else {
            header_close.addEventListener('click', () => {
                header_menu.classList.remove('active');
                document.body.classList.remove('no-scroll');
            });
        }
    } catch (err) {
        // console.warn(err);
    }

    // Intro screen
    try {
        const typeIt_holder = new TypeIt('[name="holder"].fake-form__input', {
            speed: 100,
            waitUntilVisible: true,
            strings: __CARD_HOLDER
        });
        const typeIt_number = new TypeIt('[name="number"].fake-form__input', {
            speed: 100,
            waitUntilVisible: true,
            strings: __CARD_NUMBER
        });
        const typeIt_date = new TypeIt('[name="date"].fake-form__input', {
            speed: 300,
            waitUntilVisible: true,
            strings: __CARD_DATE
        });
        const typeIt_cvc = new TypeIt('[name="cvc"].fake-form__input', {
            speed: 300,
            waitUntilVisible: true,
            strings: __CARD_CVC
        });

        (function loop() {
            document.querySelector('[name="holder"].fake-form__input').classList.add('fake-form--scaled');
            typeIt_holder.exec(() => {
                document.querySelector('[name="number"].fake-form__input').classList.add('fake-form--scaled');
                typeIt_number.exec(() => {
                    document.querySelector('[name="date"].fake-form__input').classList.add('fake-form--scaled');
                    typeIt_date.exec(() => {
                        document.querySelector('[name="cvc"].fake-form__input').classList.add('fake-form--scaled');
                        typeIt_cvc.exec(() => {
                            document.querySelector('[name="cvc"].fake-form__input').classList.remove('fake-form--scaled');
                            setTimeout(() => {
                                document.querySelector('.fake-form__btn').classList.add('fake-form--scaled')
                            }, 600)
                            setTimeout(() => {
                                document.querySelector('.fake-form__btn').classList.remove('fake-form--scaled')
                            }, 1200)

                            setTimeout(() => {
                                document.querySelectorAll('.fake-form__step')[1].classList.remove('fake-form__step--hidden')
                                document.querySelectorAll('.fake-form__step')[0].classList.add('fake-form__step--hidden')
                            }, 1800)
                            setTimeout(() => {
                                typeIt_holder.reset()
                                typeIt_number.reset()
                                typeIt_date.reset()
                                typeIt_cvc.reset()
                                document.querySelectorAll('.fake-form__step')[0].classList.remove('fake-form__step--hidden')
                                document.querySelectorAll('.fake-form__step')[1].classList.add('fake-form__step--hidden')
                                loop()
                            }, 4800)
                        }).move(null, {to: 'start'}).reset().go() // start cvc code
                        document.querySelector('[name="date"].fake-form__input').classList.remove('fake-form--scaled');
                    }).move(null, {to: 'start'}).reset().go() // start date
                    document.querySelector('[name="number"].fake-form__input').classList.remove('fake-form--scaled');
                }).move(null, {to: 'start'}).reset().go() // start number
                document.querySelector('[name="holder"].fake-form__input').classList.remove('fake-form--scaled');
            }).move(null, {to: 'start'}).reset().go() // start holder
        })()

    } catch (err) {
        // console.warn(err);
    }

    // Code screen
    try {
        const typeIt_code = new TypeIt('.code__input', {
            speed: 100,
            waitUntilVisible: true,
            html: true,
            cursor: true,
            strings: __CODE,
            loop: true
        });

        if (true) {
            typeIt_code.exec(() => {
                // console.log('typeit finish'); // finish callback
            }).go(); // start holder
        }

    } catch (err) {
        // console.warn(err);
    }

    // Swiper init
    try {
        let swiper = Swiper;
        let init = false;

        function swiperMode() {
            let mobile = window.matchMedia('(min-width: 0px) and (max-width: 768px)');
            let desktop = window.matchMedia('(min-width: 768px) and (max-width: 40000px)');

            if (desktop.matches) {
                if (!init) {
                    init = true;
                    swiper = new Swiper('.brands__slider', {
                        loop: true,
                        autoplay: {
                            delay: 2500,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true
                        },
                        slidesPerView: 6,
                        spaceBetween: 20,
                        breakpoints: {
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 20
                            },
                            992: {
                                slidesPerView: 4,
                                spaceBetween: 30
                            },
                            1223: {
                                slidesPerView: 5,
                                spaceBetween: 30
                            },
                            1440: {
                                slidesPerView: 6,
                                spaceBetween: 60
                            }
                        }
                    })
                } else {
                    init = false;
                }
            } else if (mobile.matches) {
                init && swiper.destroy();
                init = false;
            }
        }

        function swiperReview() {
            let reviewSlider = new Swiper('.reviews_slider', {
                slidesPerView: 1,
                pagination: {
                    el: '.reviews_slider_pagination',
                    clickable: true
                }
            })
        }

        if (document.querySelector('.brands__slider')) {
            swiperMode();

            window.addEventListener('resize', function () {
                swiperMode();
            });
        }

        if (document.querySelector('.reviews_slider')) swiperReview();
    } catch (err) {
        // console.warn(err);
    }

    // Custom Select ==> https://choices-js.github.io/
    try {
        // Selects search
        let noResult;

        if (document.getElementById('zip').value == 'en') {
            noResult = 'No results found';
        } else {
            noResult = 'Результат не найден';
        }

        // Selects without search
        const selects = document.querySelectorAll('.form__select:not(.form__select--search)');
        selects.forEach(el => {
            const choices = new Choices(el, {
                allowHTML: true,
                searchEnabled: false,
                shouldSort: false
            });
        });

        // Selects with search
        const selectsSearch = document.querySelectorAll('.form__select.form__select--search');
        selectsSearch.forEach(select => {
            // Filter/Sorter for countryCodeList
            if (select.name === 'countryCodeList') {
                const options = Array.from(select.options);

                const uniqueOptions = [];
                const data = new Set();

                const sortedOptions = options.sort((a, b) => {
                    const aNum = parseInt(a.value.replace(/\D/g, ''), 10);
                    const bNum = parseInt(b.value.replace(/\D/g, ''), 10);
                    const aPrefix = aNum.toString().charAt(0);
                    const bPrefix = bNum.toString().charAt(0);

                    if (aPrefix < bPrefix) return -1;
                    if (aPrefix > bPrefix) return 1;

                    if (aNum < bNum) return -1;
                    if (aNum > bNum) return 1;

                    return 0;
                });

                sortedOptions.forEach(option => {
                    const optionValue = option.value;

                    if (!data.has(optionValue)) {
                        data.add(optionValue);
                        uniqueOptions.push(option);
                    }
                });

                select.innerHTML = '';

                uniqueOptions.forEach(option => {
                    select.appendChild(option.cloneNode(true));
                });
            }

            new Choices(select, {
                allowHTML: true,
                noResultsText: noResult,
                shouldSort: false,
                resetScrollPosition: false,
                searchFields: ['label'],
                searchResultLimit: 350,
                fuseOptions: {
                    threshold: 0.1,
                    distance: 1000
                }
            });
        });
    } catch (err) {
        // console.warn(err);
    }

    try {
        function filterInt(value) {
            return /^[-+]?(\d+|Infinity)$/.test(value) ? Number(value) : NaN;
        }

        // Custom Input type=file
        let fileInput = document.querySelectorAll('input.form__file[type=file]'),
            fileTrigger = document.querySelector('.form__file-trigger'),
            fileName = fileTrigger.querySelector('.form__file-name'),
            fileNameDefault = fileName.textContent

        Array.from(fileInput).forEach(input => {
            if (input.nextElementSibling === fileTrigger) {
                fileTrigger.addEventListener('keydown', function (event) {
                    if (event.keyCode === 13 || event.keyCode === 32) {
                        input.focus()
                    }
                })
                fileTrigger.addEventListener('click', function (event) {
                    input.focus()
                    return false
                })
                input.addEventListener('change', function (event) {
                    const target = event.target,
                        getSize = target.getAttribute('data-maxsize') || 5,
                        maxAllowedSize = filterInt(getSize) * 1024 * 1024

                    if (target.value) {
                        if (target.files[0].size > maxAllowedSize) {
                            target.value = ''
                            fileName.innerHTML = fileNameDefault
                            input.setAttribute('data-required', input.getAttribute('data-name'))
                        } else {
                            fileName.classList.add('form__file-added')
                            fileName.innerHTML = target.value.split('\\').pop()
                            input.setAttribute('data-required', input.getAttribute('data-temp'))
                        }
                    } else {
                        fileName.classList.remove('form__file-added')
                        fileName.innerHTML = fileNameDefault
                        input.setAttribute('data-required', input.getAttribute('data-temp'))
                    }
                })
            }
        })
    } catch (err) {
        // console.log(err)
    }

    //Validation Form ==> https://validatejs.org/
    try {
        // Smooth Scroll for modal open/close
        window.requestAnimFrame = (function () {
            return window.requestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                function (callback) {
                    window.setTimeout(callback, 1000 / 60)
                }
        })()

        function scrollToY(scrollTargetY, speed, easing) {
            let scrollY = window.scrollY || document.documentElement.scrollTop,
                currentTime = 0

            scrollTargetY = scrollTargetY || 0
            speed = speed || 2000
            easing = easing || 'easeOutSine'

            let time = Math.max(0.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, 0.8))
            let easingEquations = {
                easeOutSine: function (pos) {
                    return Math.sin(pos * (Math.PI / 2))
                }
            }

            function tick() {
                currentTime += 1 / 60

                let p = currentTime / time,
                    t = easingEquations[easing](p)

                if (p < 1) {
                    requestAnimFrame(tick)
                    window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t))
                } else {
                    window.scrollTo(0, scrollTargetY)
                }
            }

            tick()
        }

        // Validation Params
        let constraints
        if (document.querySelectorAll('.form-contacts-js').length > 0) {
            constraints = {
                // Contact form Fields Validation
                companyName: {
                    presence: true,
                    length: {
                        maximum: 250
                    },
                    format: {
                        pattern: '^[а-яА-ЯёЁa-zA-Z0-9.,/!@#$%^&*()_+=:\'\/?|"-”„“/"?\n ]+$'
                    }
                },
                companyWebsite: {
                    presence: true,
                    length: {
                        minimum: 1,
                        maximum: 250
                    },
                    format: {
                        pattern: '((https?:)?//)?(([\\d\\w]|%[a-fA-f\\d]{2,2})+(:([\\d\\w]|%[a-fA-f\\d]{2,2})+)?@)?([\\d\\w][-\\d\\w]{0,253}[\\d\\w]\\.)+[\\w]{2,63}(:[\\d]+)?(/([-+_~.\\d\\w]|%[a-fA-f\\d]{2,2})*)*(\\?(&?([-+_~.\\d\\w]|%[a-fA-f\\d]{2,2})=?)*)?(#([-+_~.\\d\\w]|%[a-fA-f\\d]{2,2})*)?'
                    }
                },
                companyIncorporation: {
                    presence: true
                },
                productInterested: {
                    presence: true
                },
                UsrCustomerType: {
                    presence: false
                },
                message: {
                    presence: true,
                    length: {
                        minimum: 1,
                        maximum: 500
                    },
                    format: {
                        pattern: '^[а-яА-ЯёЁa-zA-Z0-9.,/!@#$%^&*()_+=:\'\/?|"-”„“/"?\n ]+$'
                    }
                },
                firstName: {
                    presence: true,
                    length: {
                        maximum: 250
                    },
                    format: {
                        pattern: '^[а-яА-ЯёЁa-zA-Z-\' ]+$'
                    }
                },
                surName: {
                    presence: true,
                    length: {
                        maximum: 250
                    },
                    format: {
                        pattern: '^[а-яА-ЯёЁa-zA-Z-\' ]+$'
                    }
                },
                businessEmail: {
                    presence: true,
                    email: true,
                    length: {
                        maximum: 250
                    }
                },
                role: {
                    length: {
                        minimum: 1,
                        maximum: 250
                    },
                    format: {
                        pattern: '^[а-яА-ЯёЁa-zA-Z0-9.,/!@#$%^&*()_+=:\'\/?|"-”„“/"?\n ]+$'
                    }
                },
                phone: {
                    // presence: true,
                    length: {
                        maximum: 250
                    },
                    format: {
                        pattern: '^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$'
                    }
                },
                acceptance: {
                    presence: true
                }
            }

            const source = document.querySelector('#productInterested')
            const target = document.querySelector('#paymentProcessingRadios')

            const displayWhenSelected = (source, value, target) => {
                const selectedIndex = source.selectedIndex
                const isSelected = source[selectedIndex].value === value

                if (isSelected) {
                    target.slideDown(200)
                    validate.extend(constraints, {UsrCustomerType: {presence: true}})
                } else {
                    target.slideUp(200)
                    validate.extend(constraints, {UsrCustomerType: {presence: false}})
                }
            }

            source.addEventListener('change', (evt) =>
                displayWhenSelected(source, 'paymentProcessing', target)
            )
        } else if (document.querySelectorAll('.form-jobs-js').length > 0) {
            constraints = {
                // Job Offer form fields validation
                jobFirstName: {
                    presence: true,
                    length: {
                        maximum: 24
                    },
                    format: {
                        pattern: '^[а-яА-ЯёЁa-zA-Z-\' ]+$'
                    }
                },
                jobLastName: {
                    presence: true,
                    length: {
                        maximum: 24
                    },
                    format: {
                        pattern: '^[а-яА-ЯёЁa-zA-Z-\' ]+$'
                    }
                },
                jobEmail: {
                    presence: true,
                    email: true,
                    length: {
                        maximum: 50
                    }
                },
                jobPhoneNumber: {
                    presence: true,
                    length: {
                        maximum: 10
                    },
                    format: {
                        pattern: '^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$'
                    }
                },
                jobMessage: {
                    length: {
                        minimum: 1,
                        maximum: 500
                    },
                    format: {
                        pattern: '^[а-яА-ЯёЁa-zA-Z0-9.,/!@#$%^&*()_+=:\'\/?|"-”„“/"?\n ]+$'
                    }
                },
                jobAttachFile: {
                    presence: true
                },
                acceptance: {
                    presence: true
                }
            }
        } else if (document.querySelectorAll('.form-program-js').length > 0) {
            constraints = {
                // Program form fields validation
                programFirstName: {
                    presence: true,
                    length: {
                        maximum: 250
                    },
                    format: {
                        pattern: '^[a-zA-Z-\' ]+$'
                    }
                },
                programSurName: {
                    presence: true,
                    length: {
                        maximum: 250
                    },
                    format: {
                        pattern: '^[a-zA-Z-\' ]+$'
                    }
                },
                programCompanyName: {
                    presence: true,
                    length: {
                        maximum: 250
                    },
                    format: {
                        pattern: '^[-a-zA-Z0-9,\'„“"& ]+$'
                    }
                },
                programRole: {
                    length: {
                        minimum: 1,
                        maximum: 250
                    },
                    format: {
                        pattern: '^[a-zA-Z- ]+$'
                    }
                },
                programEmail: {
                    presence: true,
                    email: true,
                    length: {
                        maximum: 250
                    },
                    format: {
                        pattern: '^[a-zA-Z-\.]+@([a-zA-Z-]+\.)+[a-zA-Z-]{2,4}$'
                    }
                },
                programPhone: {
                    length: {
                        minimum: 1,
                        maximum: 250
                    },
                    format: {
                        pattern: '^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$'
                    }
                },
                programWebsite: {
                    presence: true,
                    length: {
                        minimum: 1,
                        maximum: 250
                    },
                    format: {
                        pattern: '((https?:)?//)?(([\\d\\w]|%[a-fA-f\\d]{2,2})+(:([\\d\\w]|%[a-fA-f\\d]{2,2})+)?@)?([\\d\\w][-\\d\\w]{0,253}[\\d\\w]\\.)+[\\w]{2,63}(:[\\d]+)?(/([-+_~.\\d\\w]|%[a-fA-f\\d]{2,2})*)*(\\?(&?([-+_~.\\d\\w]|%[a-fA-f\\d]{2,2})=?)*)?(#([-+_~.\\d\\w]|%[a-fA-f\\d]{2,2})*)?'
                    }
                },
                programCountry: {
                    presence: true
                },
                programTypePartnership: {
                    presence: true
                },
                programTypeProduct: {
                    presence: true
                },
                programMessage: {
                    length: {
                        minimum: 1,
                        maximum: 500
                    },
                    format: {
                        pattern: '^[^\u0400-\u04FF]*$'
                    }
                },
                programAcceptance: {
                    presence: true
                }
            }
        }

        let form = document.querySelector('form#form'),
            inputs = form.querySelectorAll('input, textarea, select'),
            submitButton = form.querySelector('#sendButton')

        form.addEventListener('submit', function (ev) {
            ev.preventDefault()
            handleFormSubmit(form)
        })

        for (let i = 0; i < inputs.length; ++i) {
            inputs.item(i).addEventListener('change', function (ev) {
                let errors = validate(form, constraints) || {}
                showErrorsForInput(this, errors[this.name])
            })
        }

        function handleFormSubmit(form) {
            let errors = validate(form, constraints)
            showErrors(form, errors || {})
            if (!errors) {
                submitButton.disabled = true
                submitButton.innerHTML = 'Sending...'
                showSuccess()
            }
        }

        function showErrors(form, errors) {
            form.querySelectorAll('input[name], textarea[name], select[name]').forEach(function (input) {
                showErrorsForInput(input, errors && errors[input.name])
            })
        }

        function showErrorsForInput(input, errors) {
            if (errors) {
                if (errors[0].includes('can\'t be blank')) {
                    if (input.tagName === 'SELECT' && !input.classList.contains('form__prefix')) {
                        input.parentNode.parentNode.parentNode.classList.remove('form__control--success')
                        input.parentNode.parentNode.parentNode.classList.add('form__control--error')
                    } else {
                        if (input.getAttribute('data-required')) {
                            input.parentNode.querySelector('.form__tooltip').innerHTML = input.getAttribute('data-required')
                        }
                        input.parentNode.classList.remove('form__control--success')
                        input.parentNode.classList.add('form__control--error')
                    }
                    if (input.type === 'radio') {
                        input.parentNode.parentNode.classList.remove('form__control--success')
                        input.parentNode.parentNode.classList.add('form__control--error')
                    }

                } else if (errors[0].includes('minimum') || errors[0].includes('maximum')) {
                    input.closest('.form__control').querySelector('.form__tooltip').innerHTML = input.getAttribute('data-minmax') || input.getAttribute('data-name')
                    input.parentNode.classList.remove('form__control--success')
                    input.parentNode.classList.add('form__control--error')
                } else {
                    input.closest('.form__control').querySelector('.form__tooltip').innerHTML = input.getAttribute('data-name')
                    input.parentNode.classList.remove('form__control--success')
                    input.parentNode.classList.add('form__control--error')
                    if (input.id === 'phone' || input.id === 'programPhone') {
                        input.parentNode.parentNode.classList.remove('form__control--success')
                        input.parentNode.parentNode.classList.add('form__control--error')
                    }
                }
            } else {
                if (input.tagName === 'SELECT' && !input.classList.contains('form__prefix')) {
                    input.parentNode.parentNode.parentNode.classList.remove('form__control--error')
                    input.parentNode.parentNode.parentNode.classList.add('form__control--success')
                } else {
                    if (input.id === 'phone' || input.id === 'programPhone') {
                        input.parentNode.classList.remove('form__control--error')
                        input.parentNode.classList.add('form__control--success')
                        input.parentNode.parentNode.classList.remove('form__control--error')
                        input.parentNode.parentNode.classList.add('form__control--success')
                    } else {
                        input.parentNode.classList.remove('form__control--error')
                        input.parentNode.classList.add('form__control--success')
                    }
                }

                if (input.type === 'radio') { // && !input.parentNode.parentNode.classList.contains('collapsed')
                    input.parentNode.parentNode.classList.remove('form__control--error')
                    input.parentNode.parentNode.classList.add('form__control--success')
                }
            }
        }

        /*const inputPhone = document.querySelector('.form__phone .form__input')

        inputPhone.addEventListener('input', (event) => {
            const regex = /[^0-9]/gi

            event.target.value = event.target.value.replace(regex, '')

            if (event.target.value === '') {
                event.target.parentNode.classList.remove('form__control--success')
            }
        })*/

        function resetForm() {
            // Contacts Form
            $('[name=\'companyName\']').val('')
            $('[name=\'companyWebsite\']').val('')
            $('[name=\'companyIncorporation\']').val('')
            $('[name=\'productInterested\']').val('')
            $('[name=\'UsrCustomerType\']').val('')
            $('[name=\'message\']').val('')
            $('[name=\'firstName\']').val('')
            $('[name=\'surName\']').val('')
            $('[name=\'role\']').val('')
            $('[name=\'phone\']').val('')
            $('[name=\'businessEmail\']').val('')
            // Job Offer Form (Modal Form on Careers page)
            $('[name=\'jobFirstName\']').val('')
            $('[name=\'jobLastName\']').val('')
            $('[name=\'jobEmail\']').val('')
            $('[name=\'jobPhoneNumber\']').val('')
            $('[name=\'jobMessage\']').val('')
            $('[name=\'jobAttachFile\']').val('')
            $('[name=\'vacancyName\']').val('')
            // program Form
            $('[name=\'programFirstName\']').val('')
            $('[name=\'programSurName\']').val('')
            $('[name=\'programCompanyName\']').val('')
            $('[name=\'programRole\']').val('')
            $('[name=\'programEmail\']').val('')
            $('[name=\'programPhone\']').val('')
            $('[name=\'programWebsite\']').val('')
            $('[name=\'programCountry\']').val('')
            $('[name=\'programTypePartnership\']').val('')
            $('[name=\'programTypeProduct\']').val('')
            $('[name=\'programMessage\']').val('')
        }

        // If Valid form
        function showSuccess() {
            if (document.querySelectorAll('.form-contacts-js').length > 0) {
                load(process.env.GOOGLE_RECAPTCHA_SITE_KEY).then((recaptcha) => {
                    recaptcha.execute('validate_captcha').then((token) => {
                        document.getElementById('g-recaptcha-response').value = token
                        if (document.getElementById('g-recaptcha-response').value.length === 0) {
                            alert('reCaptcha not verified')
                            resetForm()
                        } else {
                            // Ajax send data for Contacts Form
                            let companyName = $('[name=\'companyName\']').val()
                            let companyWebsite = $('[name=\'companyWebsite\']').val()
                            let companyIncorporation = $('[name=\'companyIncorporation\']').text()
                            let productInterested = $('[name=\'productInterested\']').val()
                            let userCustomerType = $('[name=\'UsrCustomerType\']:checked').val()
                            let message = $('[name=\'message\']').val()
                            let firstName = $('[name=\'firstName\']').val()
                            let surName = $('[name=\'surName\']').val()
                            let role = $('[name=\'role\']').val()
                            let phone = $('[name=\'phone\']').val()
                            let businessEmail = $('[name=\'businessEmail\']').val()
                            let zip = $('[name=\'zip\']').val()
                            let recaptcha = $('#g-recaptcha-response').val()
                            let countryCode = '+' + document.getElementById('countryCode').innerText
                            let gcli = $('#gclid_field').val()

                            $.ajax({
                                type: 'POST',
                                url: '/send',
                                data: {
                                    companyName: companyName,
                                    companyWebsite: companyWebsite,
                                    companyIncorporation: companyIncorporation,
                                    productInterested: productInterested,
                                    UsrCustomerType: userCustomerType,
                                    message: message,
                                    firstName: firstName,
                                    surName: surName,
                                    role: role,
                                    phone: countryCode + phone,
                                    businessEmail: businessEmail,
                                    zip: zip,
                                    recaptcha: recaptcha,
                                    gcli: gcli
                                },
                                success: function (response) {
                                    if (response.result === 'success') {
                                        document.querySelector('#contacts').style.display = 'none'
                                        document.querySelector('#success').style.display = 'block'
                                        window.dataLayer.push({
                                            'event': 'registrationComplete',
                                            'role': role,
                                            'productInterested': productInterested,
                                            'companyIncorporation': companyIncorporation

                                        })
                                        scrollToY(0, 3000)
                                    } else {
                                        document.querySelector('#contacts').style.display = 'none'
                                        document.querySelector('#unsuccess').style.display = 'block'
                                        scrollToY(0, 3000)
                                    }
                                },
                                error: function () {
                                    resetForm()
                                    document.querySelector('#contacts').style.display = 'none'
                                    document.querySelector('#unsuccess').style.display = 'block'
                                    scrollToY(0, 3000)
                                }
                            })
                        }
                    })
                })
            } else if (document.querySelectorAll('.form-jobs-js').length > 0) {
                load(process.env.GOOGLE_RECAPTCHA_SITE_KEY).then((recaptcha) => {
                    recaptcha.execute('validate_captcha').then((token) => {
                        document.getElementById('g-recaptcha-response').value = token
                        if (document.getElementById('g-recaptcha-response').value.length === 0) {
                            alert('reCaptcha not verified')
                            resetForm()
                        } else {
                            // Ajax send data for Job Offer Form (Form in modal on Careers page)
                            let jobFirstName = $('[name=\'jobFirstName\']').val()
                            let jobLastName = $('[name=\'jobLastName\']').val()
                            let jobEmail = $('[name=\'jobEmail\']').val()
                            let jobPhoneNumber = $('[name=\'jobPhoneNumber\']').val()
                            let jobMessage = $('[name=\'jobMessage\']').val()
                            let recaptcha = $('#g-recaptcha-response').val()
                            let language = $('#language')[0].value;
                            let jobAttachFile = $('[name=\'jobAttachFile\']')[0].files;
                            let formData = new FormData()

                            if (jobAttachFile.length > 0) {
                                formData.append('file', jobAttachFile[0])
                            }

                            formData.append('firstName', jobFirstName);
                            formData.append('lastName', jobLastName);
                            formData.append('email', jobEmail);
                            formData.append('phone', jobPhoneNumber);
                            formData.append('message', jobMessage);
                            formData.append('recaptcha', recaptcha);
                            formData.append('language', language);

                            if ($('[name=\'vacancyName\']').val().length > 0) {
                                let vacancyName = $('[name=\'vacancyName\']').val()
                                formData.append('vacancyName', vacancyName);
                            }

                            $.ajax({
                                type: 'POST',
                                url: '/send-cv',
                                processData: false, // tell jQuery not to process the data
                                contentType: false, // tell jQuery not to set contentType
                                data: formData,
                                success: function (response) {
                                    if (response.result === 'success') {
                                        document.querySelector('.modal.form-modal').style.display = 'none'
                                        document.querySelector('#success').style.display = 'block'
                                        scrollToY(0, 3000)
                                    } else {
                                        document.querySelector('.modal.form-modal').style.display = 'none'
                                        document.querySelector('#unsuccess').style.display = 'block'
                                        scrollToY(0, 3000)
                                    }
                                },
                                error: function () {
                                    resetForm()
                                    document.querySelector('.modal.form-modal').style.display = 'none'
                                    document.querySelector('#unsuccess').style.display = 'block'
                                    scrollToY(0, 3000)
                                }
                            })
                        }
                    })
                })
            } else if (document.querySelectorAll('.form-program-js').length > 0) {
                load(process.env.GOOGLE_RECAPTCHA_SITE_KEY).then((recaptcha) => {
                    recaptcha.execute('validate_captcha').then((token) => {
                        document.getElementById('g-recaptcha-response').value = token
                        if (document.getElementById('g-recaptcha-response').value.length === 0) {
                            alert('reCaptcha not verified')
                            resetForm()
                        } else {
                            // Ajax send data for Program Form
                            let programFirstName = $('[name=\'programFirstName\']').val()
                            let programSurName = $('[name=\'programSurName\']').val()
                            let programCompanyName = $('[name=\'programCompanyName\']').val()
                            let programRole = $('[name=\'programRole\']').val()
                            let programEmail = $('[name=\'programEmail\']').val()
                            let countryCode = '+' + document.getElementById('countryCode').innerText
                            let programPhone = $('[name=\'programPhone\']').val()
                            let programWebsite = $('[name=\'programWebsite\']').val()
                            let programCountry = $('[name=\'programCountry\']').text()
                            let programTypePartnership = $('[name=\'programTypePartnership\']').val()
                            let programTypeProduct = $('[name=\'programTypeProduct\']').val()
                            let programMessage = $('[name=\'programMessage\']').val()
                            let zip = $('[name=\'zip\']').val()
                            let recaptcha = $('#g-recaptcha-response').val()

                            // Ajax send data for Program Form
                            $.ajax({
                                type: 'POST',
                                url: '/partner',
                                data: {
                                    firstName: programFirstName,
                                    surName: programSurName,
                                    companyName: programCompanyName,
                                    role: programRole,
                                    email: programEmail,
                                    phone: countryCode + programPhone,
                                    website: programWebsite,
                                    country: programCountry,
                                    typePartnership: programTypePartnership,
                                    typeProduct: programTypeProduct,
                                    message: programMessage,
                                    zip: zip,
                                    recaptcha: recaptcha
                                },
                                success: function (response) {
                                    if (response.result === 'success') {
                                        document.querySelector('#program').style.display = 'none'
                                        document.querySelector('#success').style.display = 'block'
                                        scrollToY(0, 3000)
                                    } else {
                                        document.querySelector('#program').style.display = 'none'
                                        document.querySelector('#unsuccess').style.display = 'block'
                                        scrollToY(0, 3000)
                                    }
                                },
                                error: function () {
                                    resetForm()
                                    document.querySelector('#program').style.display = 'none'
                                    document.querySelector('#unsuccess').style.display = 'block'
                                    scrollToY(0, 3000)
                                }
                            })
                        }
                    })
                })
            }
        }
    } catch (err) {
        // console.warn(err);
    }

    $('#countryCodeList').change(function () {
        let code = document.getElementById('countryCodeList')[0].value;
        document.getElementById('countryCode').innerText = code;
    });

    // Media Page
    try {
        const filNav = document.querySelector('.filter')
        const filBtn = document.querySelectorAll('.filter_button')
        const filMob = document.querySelector('.filter_mobile')

        filNav.querySelector('.burger').addEventListener('click', () => {
            document.querySelector('.hero_section').style.display = 'contents'
            document.body.classList.add('no-scroll')
            filNav.querySelector('.filter_menu').classList.add('active')
        })

        function filterClose() {
            document.querySelector('.hero_section').style.display = 'block'
            document.body.classList.remove('no-scroll')
            filNav.querySelector('.filter_menu').classList.remove('active')
        }

        filNav.querySelector('.close').addEventListener('click', () => {
            filterClose()
        })

        filNav.addEventListener('click', function (e) {
            let target = e.target

            if (target.classList.contains('filter_button')) {
                filBtn.forEach(b => {
                    b.classList.remove('active')
                })

                target.classList.add('active')
                if (filMob.querySelector('.burger span')) {
                    filMob.querySelector('.burger span').innerHTML = target.innerHTML
                }
                filterClose()
            }
        })
    } catch (err) {
        // console.warn(err);
    }
});

// Documents page navigation + scroll animation
try {
    window.requestAnimFrame = (function () {
        return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame ||
            function (callback) {
                window.setTimeout(callback, 1000 / 60)
            }
    })()

    function scrollToY(scrollTargetY, speed, easing) {
        let scrollY = window.scrollY || document.documentElement.scrollTop,
            currentTime = 0

        scrollTargetY = scrollTargetY || 0
        speed = speed || 2000
        easing = easing || 'easeOutSine'

        let time = Math.max(0.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, 0.8)),
            easingEquations = {
                easeOutSine: function (pos) {
                    return Math.sin(pos * (Math.PI / 2))
                },
                easeInOutSine: function (pos) {
                    return (-0.5 * (Math.cos(Math.PI * pos) - 1))
                }
            }

        function tick() {
            currentTime += 1 / 60;

            let p = currentTime / time,
                t = easingEquations[easing](p)

            if (p < 1) {
                requestAnimFrame(tick);
                window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t))
            } else {
                window.scrollTo(0, scrollTargetY)
            }
        }

        tick()
    }

    function menuControl(menu) {
        let scrollPos = window.scrollY || document.documentElement.scrollTop,
            links = menu.querySelectorAll('a[href^="#"]'),
            heightHeaderFooter = document.querySelector('.header').clientHeight + document.querySelector('.footer').clientHeight

        for (let i = 0; i < links.length; i++) {
            let currLink = links[i],
                refElement = document.querySelector(currLink.getAttribute('href')),
                scrollElement = refElement.offsetTop

            currLink.parentNode.classList.toggle(
                'active',
                scrollElement <= scrollPos + heightHeaderFooter && scrollElement + refElement.clientHeight > scrollPos + heightHeaderFooter
            )
        }
    }

    function animated(menu, speed, easing) {
        let i,
            links = menu.querySelectorAll('a[href^="#"]'),
            heightHeaderFooter = document.querySelector('.header').clientHeight + document.querySelector('.footer').clientHeight

        function control(e) {
            let target = document.querySelector(this.hash)

            e.preventDefault()
            scrollToY(target.offsetTop - heightHeaderFooter + 1, speed, easing)

            document.querySelector('.docs_page .sidebar').classList.remove('active')
            document.body.classList.remove('no-scroll')
        }

        for (i = 0; i < links.length; i++) {
            links[i].addEventListener('click', control)
        }
    }

    function scrollSpy(menu, speed, easing) {
        animated(menu, speed, easing)
        menuControl(menu)

        document.addEventListener('scroll', function () {
            menuControl(menu)
        })
    }

    scrollSpy(document.querySelector('.sidebar_list'), 1, 'easeOutSine')

    /*function isElementInView(element) {
        const bounding = element.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;

        return (bounding.top >= 0 && bounding.bottom <= windowHeight);
    }

    function activateMenuElement(link) {
        const menuItems = document.querySelectorAll('.sidebar_list li');
        for (const menuItem of menuItems) {
            menuItem.classList.remove('active');
        }
        link.parentNode.classList.add('active');
    }

    function scrollSpy(menu, speed, easing) {
        const links = menu.querySelectorAll('a[href^="#"]');

        function control(e) {
            e.preventDefault();
            const target = document.querySelector(this.hash);
            scrollToY(target.offsetTop, speed, easing);
        }

        for (const link of links) {
            link.addEventListener('click', control);
        }

        function handleScroll() {
            const headerHeight = document.querySelector('.header').offsetHeight;
            const footerHeight = document.querySelector('.footer').offsetHeight;

            let activeLink = null;

            for (const link of links) {
                const target = document.querySelector(link.hash);

                if (isElementInView(target)) {
                    activeLink = link;
                }
            }

            if (activeLink) {
                const isAtEndOfPage = window.innerHeight + window.pageYOffset >=
                    (document.body.offsetHeight - headerHeight - footerHeight) - 1;
                if (isAtEndOfPage) {
                    activateMenuElement(links[links.length - 1]);
                } else {
                    activateMenuElement(activeLink);
                }
            }
        }
        handleScroll();

        const throttleHandleScroll = throttle(handleScroll, 100);
        document.addEventListener('scroll', throttleHandleScroll);
    }

    function throttle(fn, delay) {
        let lastCall = 0;
        return function (...args) {
            const now = new Date().getTime();
            if (now - lastCall < delay) {
                return;
            }
            lastCall = now;
            fn(...args);
        };
    }

    scrollSpy(document.querySelector('.sidebar_list'), 1, 'easeOutSine');*/



} catch (err) {
    // console.warn(err);
}

// Careers page
try {
    let address_list = document.querySelector('.address_list'),
        address_items = address_list.querySelectorAll('.address_item'),
        map,
        marker,
        markers = []

    address_items.forEach((btn, i) => {
        let dataInfo = btn.querySelector('.address_info'),
            markersInfo = {
                'title': `${dataInfo.getAttribute('data-title')}`,
                'lat': `${dataInfo.getAttribute('data-lat')}`,
                'lng': `${dataInfo.getAttribute('data-lng')}`,
                'description': `${dataInfo.getAttribute('data-description')}`,
                'icon': `${dataInfo.getAttribute('data-icon')}`,
                'type': 'info'
            }

        markers.push(markersInfo)

        btn.addEventListener('click', () => {
            address_list.querySelector('li.address_item--active').classList.remove('address_item--active')
            btn.classList.add('address_item--active')
            SetMarker(i)
        })
    })

    window.onload = function () {
        LoadMap()
    }

    function LoadMap() {
        let mapOptions = {
            center: new google.maps.LatLng(markers[0].lat, markers[0].lng),
            zoom: 10,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: [{
                'featureType': 'all',
                'elementType': 'labels.text.fill',
                'stylers': [{'saturation': '0'}, {'color': '#4996d1'}, {'lightness': '0'}, {'gamma': '1'}]
            }, {
                'featureType': 'all',
                'elementType': 'labels.text.stroke',
                'stylers': [{'visibility': 'on'}, {'color': '#ffffff'}, {'lightness': 16}]
            }, {
                'featureType': 'all',
                'elementType': 'labels.icon',
                'stylers': [{'visibility': 'off'}]
            }, {
                'featureType': 'administrative',
                'elementType': 'geometry.fill',
                'stylers': [{'color': '#fefefe'}, {'lightness': 20}]
            }, {
                'featureType': 'administrative',
                'elementType': 'geometry.stroke',
                'stylers': [{'color': '#fefefe'}, {'lightness': 17}, {'weight': 1.2}]
            }, {
                'featureType': 'landscape',
                'elementType': 'geometry',
                'stylers': [{'color': '#f5f5f5'}, {'lightness': 20}]
            }, {
                'featureType': 'poi',
                'elementType': 'geometry',
                'stylers': [{'color': '#f5f5f5'}, {'lightness': 21}]
            }, {
                'featureType': 'poi.park',
                'elementType': 'geometry',
                'stylers': [{'color': '#dedede'}, {'lightness': 21}]
            }, {
                'featureType': 'road.highway',
                'elementType': 'geometry.fill',
                'stylers': [{'color': '#ffffff'}, {'lightness': 17}]
            }, {
                'featureType': 'road.highway',
                'elementType': 'geometry.stroke',
                'stylers': [{'color': '#ffffff'}, {'lightness': 29}, {'weight': 0.2}]
            }, {
                'featureType': 'road.arterial',
                'elementType': 'geometry',
                'stylers': [{'color': '#ffffff'}, {'lightness': 18}]
            }, {
                'featureType': 'road.local',
                'elementType': 'geometry',
                'stylers': [{'color': '#ffffff'}, {'lightness': 16}]
            }, {
                'featureType': 'transit',
                'elementType': 'geometry',
                'stylers': [{'color': '#f2f2f2'}, {'lightness': 19}]
            }, {
                'featureType': 'water',
                'elementType': 'geometry',
                'stylers': [{'color': '#c0d1ee'}, {'lightness': 17}]
            }]
        }

        map = new google.maps.Map(document.getElementById('map'), mapOptions)
        SetMarker(0)
    }

    function SetMarker(position) {
        //Remove previous Marker.
        if (marker != null) {
            marker.setMap(null)
        }

        //Set Marker on Map.
        let data = markers[position],
            myLatlng = new google.maps.LatLng(data.lat, data.lng)

        marker = new google.maps.Marker({
            position: myLatlng,
            map: map,
            icon: data.icon,
            title: data.title
        })

        map.setCenter(marker.position)
    }

    // Smooth Scroll for modal open/close
    window.requestAnimFrame = (function () {
        return window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            function (callback) {
                window.setTimeout(callback, 1000 / 60)
            }
    })()

    function scrollToY(scrollTargetY, speed, easing) {
        let scrollY = window.scrollY || document.documentElement.scrollTop,
            currentTime = 0

        scrollTargetY = scrollTargetY || 0
        speed = speed || 2000
        easing = easing || 'easeOutSine'

        let time = Math.max(0.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, 0.8))
        let easingEquations = {
            easeOutSine: function (pos) {
                return Math.sin(pos * (Math.PI / 2))
            }
        }

        function tick() {
            currentTime += 1 / 60

            let p = currentTime / time,
                t = easingEquations[easing](p)

            if (p < 1) {
                requestAnimFrame(tick)
                window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t))
            } else {
                window.scrollTo(0, scrollTargetY)
            }
        }

        tick()
    }

    // Modal
    let modalTriggers = document.querySelectorAll('.modal_trigger'),
        modalClose = document.querySelectorAll('.modal_close'),
        hideContent = document.querySelector('.careers'),
        buttonPosition = 0

    // Modal Open
    Array.from(modalTriggers).forEach(trigger => {
        trigger.addEventListener('click', function (e) {
            e.preventDefault()

            const modalName = trigger.dataset.modal,
                modal = document.querySelector('.' + modalName)

            buttonPosition = window.scrollY
            hideContent.classList.add('hide_content')
            modal.classList.add('modal_show')
            scrollToY(0, 3000)

            if (trigger.closest('.accordion__item')) {
                modal.querySelector('#vacancyName').setAttribute('value', trigger.closest('.accordion__item').querySelector('.accordion__button').textContent.trim())
            }
        })
    })

    // Modal Close
    Array.from(modalClose).forEach(closeSymbol => {
        closeSymbol.addEventListener('click', function (e) {
            e.preventDefault()

            hideContent.classList.remove('hide_content')
            this.closest('.modal').classList.remove('modal_show')
            scrollToY(buttonPosition, 3000)

            buttonPosition = 0

            this.closest('.modal').querySelector('#vacancyName').setAttribute('value', '')
        })
    })
} catch (err) {
    // console.warn(err);
}

try {
    // Accordion
    const accordionTitles = document.querySelectorAll('.accordion .accordion-button')
    const accordionItems = document.querySelectorAll('.accordion .accordion-item')

    function openAccordion(accordion) {
        // Opened accordion and add active class
        accordion.querySelector('.accordion-collapse').slideDown(300)
        accordion.classList.add('opened')
    }

    function closeAccordion(accordion) {
        // Closed accordion and remove active class
        accordion.querySelector('.accordion-collapse').slideUp(300)
        // setTimeout(function () {
        accordion.classList.remove('opened')
        // }, 300)
    }

    // Title bar accordion - Click
    accordionTitles.forEach((el) => {
        el.addEventListener('click', e => {
            e.preventDefault()
            const currentAccordion = e.currentTarget.parentElement.parentElement

            for (const accordion of accordionItems) {
                // Close accordion
                if (!accordion.querySelector('.accordion-collapse').classList.contains('d-none')) {
                    closeAccordion(accordion)
                }
            }

            if (currentAccordion.querySelector('.accordion-collapse').classList.contains('d-none')) {
                openAccordion(currentAccordion)
            } else {
                closeAccordion(currentAccordion)
            }
        })
    })
} catch (err) {
    // console.warn(err);
}

// Auto height for grid table (Post template)
try {
    const gridTables =  document.querySelectorAll('.table_block_grid')
    const breakpoint = window.matchMedia('(min-width:769px)')
    let gridTablesSlider;

    if (gridTables.length > 0) {
        gridTables.forEach(gridTable => {
            updateTableHeights(gridTable)
            window.addEventListener('resize', () => updateTableHeights(gridTable))
        })

        sliderInitial()
        breakpoint.addListener(sliderInitial)
    }
    function updateTableHeights(gridTable) {
        if (window.getComputedStyle(gridTable).display === 'none') return;

        const gridTableColumns = gridTable.querySelectorAll('.table_block_grid_col'),
            headerHeights = [],
            contentHeights = []

        if (gridTableColumns.length === 0) return;

        gridTableColumns.forEach(column => {
            const headerElements = column.querySelectorAll('.table_block_grid_heading'),
                contentElements = column.querySelectorAll('.table_block_grid_content')

            headerElements.forEach((header, rowIndex) => {
                header.style.setProperty('--grid-table-header-height', '')
                headerHeights[rowIndex] = Math.max(headerHeights[rowIndex] || 0, header.offsetHeight)
            })
            contentElements.forEach((content, rowIndex) => {
                content.style.setProperty('--grid-table-content-height', '')
                contentHeights[rowIndex] = Math.max(contentHeights[rowIndex] || 0, content.offsetHeight)
            })
        })

        gridTableColumns.forEach(column => {
            const headerElements = column.querySelectorAll('.table_block_grid_heading'),
                contentElements = column.querySelectorAll('.table_block_grid_content')

            headerElements.forEach((header, rowIndex) => {
                header.style.setProperty('--grid-table-header-height', `${headerHeights[rowIndex]}px`)
            })
            contentElements.forEach((content, rowIndex) => {
                content.style.setProperty('--grid-table-content-height', `${contentHeights[rowIndex]}px`)
            })
        })
    }

    function sliderInitial() {
        document.querySelectorAll('.table_block_grid').forEach(sliderContainer => {
            let swiperInstance = sliderContainer.swiper;

            if (breakpoint.matches === true) {
                if (swiperInstance) swiperInstance.destroy(true, true)
            } else {
                if (!swiperInstance) {
                    new Swiper(/** @type {HTMLElement} */sliderContainer, {
                        loop: true,
                        slidesPerView: 1.2,
                        autoHeight: true,
                        grabCursor: true,
                        spaceBetween: '3.5%',
                        pagination: {
                            el: sliderContainer.querySelector('.swiper-pagination'),
                            type: 'bullets',
                            clickable: true
                        },
                        navigation: {
                            nextEl: sliderContainer.querySelector('.swiper-button-next'),
                            prevEl: sliderContainer.querySelector('.swiper-button-prev')
                        }
                    })
                }
            }
        })
    }
} catch (err) {
    console.warn(err);
}


// Swiper sliders Guide page
(function () {
    'use strict'

    if (!document.querySelector('.guide-slider-tabs')) return;

    // breakpoint where swiper will be destroyed
    const breakpoint = window.matchMedia('(min-width:992px)')

    // keep track of swiper instances to destroy later
    let guideContents1,
        guideImages1,
        guideContents2,
        guideImages2,
        guideContents3,
        guideImages3,
        guideContents4,
        guideImages4

    // desktop sliders destroyed on mobile
    let sliderTabsContent1,
        sliderTabsImages1,
        sliderTabsContent2,
        sliderTabsImages2

    const carouselOptionGuideImages = {
        loop: true,
        slidesPerView: 1,
        centeredSlides: true,
        autoHeight: true,
        grabCursor: true,
        spaceBetween: 20,
        autoplay: { // new fix
            delay: 4000,
            disableOnInteraction: false
        }
    }

    const breakpointChecker = function () {
        // if larger viewport and multi-row layout needed
        if (breakpoint.matches === true) {
            // clean up old instances and inline styles when available
            if (
                guideContents1 !== undefined && guideImages1 !== undefined ||
                guideContents2 !== undefined && guideImages2 !== undefined ||
                guideContents3 !== undefined && guideImages3 !== undefined ||
                guideContents4 !== undefined && guideImages4 !== undefined
            ) {
                guideContents1.destroy(true, true)
                guideImages1.destroy(true, true)
                guideContents2.destroy(true, true)
                guideImages2.destroy(true, true)
                guideContents3.destroy(true, true)
                guideImages3.destroy(true, true)
                guideContents4.destroy(true, true)
                guideImages4.destroy(true, true)
            }
            // or/and do nothing
            return enableDesktopSwiper()
            // else if a small viewport and single column layout needed
        } else if (breakpoint.matches === false) {
            if (
                sliderTabsContent1 !== undefined && sliderTabsImages1 !== undefined ||
                sliderTabsContent2 !== undefined && sliderTabsImages2 !== undefined
            ) {
                sliderTabsContent1.destroy(true, true)
                sliderTabsImages1.destroy(true, true)
                sliderTabsContent2.destroy(true, true)
                sliderTabsImages2.destroy(true, true)
            }
            // fire small viewport version of swiper
            return enableSwiper()
        }
    }

    const enableDesktopSwiper = function () {
        sliderTabsContent1 = new Swiper('.guide-slider-tabs .guide-slider-contents-3', {
            direction: 'vertical',
            speed: 1000,
            slidesPerView: 4,
            spaceBetween: 0
        })

        sliderTabsImages1 = new Swiper('.guide-slider-tabs .guide-slider-images-3', {
            direction: 'vertical',
            speed: 1000,
            effect: 'fade',
            slidesPerView: 1,
            spaceBetween: 48,
            grabCursor: true,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false
            },
            thumbs: {
                swiper: sliderTabsContent1
            }
        })

        sliderTabsContent2 = new Swiper('.guide-slider-tabs .guide-slider-contents-4', {
            direction: 'vertical',
            speed: 1000,
            slidesPerView: 4,
            spaceBetween: 0
        })

        sliderTabsImages2 = new Swiper('.guide-slider-tabs .guide-slider-images-4', {
            direction: 'vertical',
            speed: 1000,
            effect: 'fade',
            slidesPerView: 1,
            spaceBetween: 48,
            grabCursor: true,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false
            },
            thumbs: {
                swiper: sliderTabsContent2
            }
        })
    }

    const enableSwiper = function () {
        guideContents1 = new Swiper('.guide-slider-contents-1', {
            loop: true, // false
            slidesPerView: 1,
            centeredSlides: true,
            autoHeight: true,
            grabCursor: true,
            spaceBetween: 20,
            pagination: {
                el: '.guide-slider-navigation-1 .swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            navigation: {
                nextEl: '.guide-slider-navigation-1 .swiper-button-next',
                prevEl: '.guide-slider-navigation-1 .swiper-button-prev'
            }
        })
        guideImages1 = new Swiper('.guide-slider-images-1', carouselOptionGuideImages)

        guideContents2 = new Swiper('.guide-slider-contents-2', {
            loop: true,
            slidesPerView: 1,
            centeredSlides: true,
            autoHeight: true,
            grabCursor: true,
            spaceBetween: 20,
            pagination: {
                el: '.guide-slider-navigation-2 .swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            navigation: {
                nextEl: '.guide-slider-navigation-2 .swiper-button-next',
                prevEl: '.guide-slider-navigation-2 .swiper-button-prev'
            }
        })
        guideImages2 = new Swiper('.guide-slider-images-2', carouselOptionGuideImages)

        guideContents3 = new Swiper('.guide-slider-contents-3', {
            loop: true,
            slidesPerView: 1,
            centeredSlides: true,
            autoHeight: true,
            grabCursor: true,
            spaceBetween: 20,
            pagination: {
                el: '.guide-slider-navigation-3 .swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            navigation: {
                nextEl: '.guide-slider-navigation-3 .swiper-button-next',
                prevEl: '.guide-slider-navigation-3 .swiper-button-prev'
            }
        })
        guideImages3 = new Swiper('.guide-slider-images-3', carouselOptionGuideImages)

        guideContents4 = new Swiper('.guide-slider-contents-4', {
            loop: true,
            slidesPerView: 1,
            centeredSlides: true,
            autoHeight: true,
            grabCursor: true,
            spaceBetween: 20,
            pagination: {
                el: '.guide-slider-navigation-4 .swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            navigation: {
                nextEl: '.guide-slider-navigation-4 .swiper-button-next',
                prevEl: '.guide-slider-navigation-4 .swiper-button-prev'
            }
        })
        guideImages4 = new Swiper('.guide-slider-images-4', carouselOptionGuideImages)

        // Assign each other controls
        guideImages1.controller.control = guideContents1
        guideContents1.controller.control = guideImages1
        guideImages2.controller.control = guideContents2
        guideContents2.controller.control = guideImages2
        guideImages3.controller.control = guideContents3
        guideContents3.controller.control = guideImages3
        guideImages4.controller.control = guideContents4
        guideContents4.controller.control = guideImages4
    }

    // keep an eye on viewport size changes
    breakpoint.addListener(breakpointChecker)

    // kickstart
    breakpointChecker()


    // ========================================================
    /*const createCarousel = (carouselArray, instanceName, container, options) => {
        carouselArray.forEach((element, index) => {
            element.classList.add(instanceName + index)
            container[index] = new Swiper('.' + instanceName + index, options)
        })
    }

    // keep track of swiper instances to destroy later
    const carouselsGuideContents = [],
        carouselsGuideImages = []

    const carouselArrayGuideContents = Array.from(
            document.querySelectorAll('.guide-slider-contents')
        ),
        carouselArrayGuideImages = Array.from(
            document.querySelectorAll('.guide-slider-images')
        )

    const carouselOptionGuideContents = {
            loop: false,
            slidesPerView: 1,
            centeredSlides: true,
            autoHeight: true,
            grabCursor: true,
            spaceBetween: 20,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        },
        carouselOptionGuideImages = {
            loop: false,
            slidesPerView: 1,
            centeredSlides: true,
            autoHeight: true,
            grabCursor: true,
            spaceBetween: 20
        }

    // breakpoint where swiper will be destroyed
    // and switches to a dual-column layout
    const breakpoint = window.matchMedia('(min-width:992px)')

    const breakpointChecker = function () {
        // if larger viewport and multi-row layout needed
        if (breakpoint.matches === true) {
            // clean up old instances and inline styles when available
            if (carouselsGuideContents !== undefined && carouselsGuideImages !== undefined) {
                for (let i = 0; i < carouselsGuideContents.length; i++) {
                    carouselsGuideContents[i].destroy(true, true)
                }
                for (let i = 0; i < carouselsGuideImages.length; i++) {
                    carouselsGuideImages[i].destroy(true, true)
                }
            }
            // or/and do nothing
            return false
            // else if a small viewport and single column layout needed
        } else if (breakpoint.matches === false) {
            // fire small viewport version of swiper
            return enableSwiper()
        }
    }

    const enableSwiper = function () {
        createCarousel(
            carouselArrayGuideContents,
            'guide-slider-contents-',
            carouselsGuideContents,
            carouselOptionGuideContents
        )

        createCarousel(
            carouselArrayGuideImages,
            'guide-slider-images-',
            carouselsGuideImages,
            carouselOptionGuideImages
        )

        // Assign each other controls
        carouselArrayGuideImages.forEach((element, index) => {
            carouselsGuideContents[index].controller.control = carouselsGuideImages[index]
            carouselsGuideImages[index].controller.control = carouselsGuideContents[index]

        })

        carouselArrayGuideContents.forEach((element, index) => {
        })
    }

    // keep an eye on viewport size changes
    breakpoint.addListener(breakpointChecker)

    // kickstart
    breakpointChecker()*/
})()
