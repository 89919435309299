import * as $ from 'jquery';

export default class AjaxLink {
    $el;
    $container;
    loading = false;
    enableLoader;
    loadingClass;

    initialize = (el, options) => {
        this.$el = $(el);
        this.$container = options.container || $(`#${el.id}-container`);
        this.enableLoader = !!options.loadingClass || !!options.enableLoading;
        this.loadingClass = options.loadingClass || `${this.getFirstClass(this.$el)}--loading`;

        this.bindEvents();
    };

    getFirstClass = ($item) => {
        return $item[0].classList[0];
    };

    bindEvents = () => {
        this.$el.on('click', this.handleClick);
    };

    get url() {
        const url = new URL(this.$el.attr('href'), window.location.href);
        url.searchParams.set('partial', 'true');

        return url.href;
    }

    appendItems = (items) => {
        this.$container.append(items);
    };

    changeHref = (href) => {
        this.$el.attr('href', href);
    };

    removeLoadMoreLink = () => {
        this.$el.remove();
    };

    toggleLoadingClass = () => {
        this.$el.toggleClass(this.loadingClass);
    };

    handleClick = (e) => {
        e.preventDefault();

        if (this.loading) {
            return;
        }

        this.loading = true;

        if (this.enableLoader) {
            this.toggleLoadingClass();
        }

        $.ajax(this.url, {
            error: () => {
                this.removeLoadMoreLink();
            },
            success: (data) => {
                data = $(data);

                const items = data.find(`#${this.$container.attr('id')}`).html();
                const loadMoreLink = data.find(`#${this.$el.attr('id')}`);

                this.appendItems(items);

                if (loadMoreLink.length) {
                    this.changeHref(loadMoreLink.attr('href'));
                } else {
                    this.removeLoadMoreLink();
                }
            },
            complete: () => {
                if (this.enableLoader) {
                    this.toggleLoadingClass();
                }

                this.loading = false;
            },
        });
    };
}